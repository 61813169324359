<template>
  <div class="max-w-md mx-auto mt-10">
    <h2 class="text-2xl font-bold">Transactions</h2>
    <ul>
      <li v-for="transaction in transactions" :key="transaction.id" class="border p-2 mt-2">
        <p><strong>Type:</strong> {{ transaction.transaction_type }}</p>
        <p><strong>Amount:</strong> {{ transaction.amount }}</p>
        <p><strong>Date:</strong> {{ transaction.created_at }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios';
import { API_BASE_URL } from '../config';
export default {
  name: 'TransactionsPage',
  data() {
    return {
      transactions: []
    };
  },
  async created() {
    try {
      const response = await axios.get(`${API_BASE_URL}/transactions`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      this.transactions = response.data.transactions;
    } catch (error) {
      console.error(error.response.data);
    }
  }
}
</script>
