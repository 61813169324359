<template>
    <a href="#" class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
      <img class="w-8 h-8 mr-2" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/logo.svg" alt="logo">
      Flowbite    
    </a>
  </template>
  
  <script>
  export default {
    name: 'LogoSection',
  };
  </script>
  
  <style scoped>
  /* Additional styling if needed */
  </style>
  