//import { createRouter, createWebHistory } from 'vue-router'
import { createRouter, createWebHistory } from '@ionic/vue-router';
import HomePage from '../views/Home.vue'
import RegisterPage from '../views/Authentication/Register.vue'
import LoginPage from '../views/Authentication/Login.vue'
import ForgotPasswordPage from '../views/Authentication/ForgotPassword'
import AuthenticatedLayout from '../layouts/AuthenticatedLayout';
import ProfilePage from '../views/Profile.vue'
import MyProfilePage from '../views/Account/MyProfile.vue';
import TransactionsPage from '../views/Transactions.vue'
import DashboardPage from '../views/Account/Dashboard.vue';
import AccountActivityPage from '../views/Account/AccountActivity.vue';

const routes = [
  { path: '/', component: HomePage },
  { path: '/register', component: RegisterPage },
  { path: '/login', component: LoginPage },
  { path: '/forgotpassword', component: ForgotPasswordPage },

  { 
    path: '/',
    component: AuthenticatedLayout,
    meta: {requiresAuth: true},
    children: [
      {path:'dashboard', component: DashboardPage},
      {path:'profile', component: ProfilePage},
      {path:'accountactivitykes', component: AccountActivityPage},
      {path:'myprofile', component: MyProfilePage},
      {path: 'transations', component: TransactionsPage}
    ]
 }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

// Authentication guard
router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('token');
  if (to.meta.requiresAuth && !isAuthenticated) {
    next({ path: '/login' });
  } else {
    next();
  }
});
export default router
