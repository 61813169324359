<template>
    <div class="container mx-auto p-6">
      <AccountActivityKES :transactions="transactions" />
    </div>
  </template>
  
  <script>
  import AccountActivityKES from '../../components/AccountActivityKES.vue';
  
  export default {
    name:'AccountActivityPage',
    components: { AccountActivityKES },
    data() {
      return {
        transactions: [
          { date: '2024-11-01', type: 'deposit', currency: 'KES', amount: 10000 },
          { date: '2024-11-02', type: 'earning', currency: 'KES', amount: 1500 },
          { date: '2024-11-03', type: 'withdrawal', currency: 'KES', amount: 2000 },
          // Add more transactions as needed
        ],
      };
    },
  };
  </script>
  