<template>
    <div class="antialiased bg-gray-50 dark:bg-gray-900">
        <Navbar />
        <!-- Sidebar -->
        <Sidebar />
   
       <main class="p-4 md:ml-64 h-auto pt-20">
        <router-view />

       </main>
    </div>
 </template>

<script>
import axios from 'axios';
import { API_BASE_URL } from '../config';
import Sidebar from '../components/Sidebar.vue';
import Navbar from '../components/Navbar.vue';
import { ref, onMounted, provide, computed } from 'vue'
import { initFlowbite } from 'flowbite'


export default {
  components: {
    Sidebar,
    Navbar
  },
  setup() {
    const user = ref(null);

    // Fetch user data once after login
    onMounted(async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/profile`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        user.value = response.data.user;
      } catch (error) {
        console.error(error.response?.data || 'Failed to load user');
      }
    });

    // Provide the user data to child components
    provide('user', computed(() => user.value));
    // initialize components based on data attribute selectors
    onMounted(() => {
        initFlowbite();
    });
    return { user };
  }
};
</script>

<style>
/* Add your layout-specific styles here */
</style>