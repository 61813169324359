<template>
                <a href="#" class="flex items-center justify-between mr-4">
                <img
                   src="https://flowbite.s3.amazonaws.com/logo.svg"
                   class="mr-3 h-8"
                   alt="Flowbite Logo"
                   />
                <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Flowbite</span>
                </a>
  </template>
  
  <script>
  export default {
    name: 'LogoSectionAuthenticated',
  };
  </script>
  
  <style scoped>
  /* Additional styling if needed */
  </style>
  