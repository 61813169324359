<template>
    <div class="container mx-auto p-6">
      <h1 class="text-3xl font-bold text-gray-800 mb-6">Dashboard</h1>
      
      <!-- Include TradingSnapshot Component -->
      <TradingSnapshot
        :userName="user.name"
        :currentDeposit="user.currentDeposit"
        :currentEarnings="user.currentEarnings"
        :progress="user.progress"
      />
    </div>
  </template>
  
  <script>
  import TradingSnapshot from '../../components/TradingSnapshot.vue';
  
  export default {
    name: 'DashboardPage',
    components: {
      TradingSnapshot,
    },
    data() {
      return {
        // Example user data, replace with actual API or store data
        user: {
          name: 'Comark',
          currentDeposit: 5000,
          currentEarnings: 1200,
          progress: 60, // 60% of investment goal reached
        },
      };
    },
  }
  </script>
  
  <style scoped>
  /* Add any additional styling for the dashboard here */
  </style>
  