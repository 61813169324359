<template>
  <div class="max-w-md mx-auto mt-10 bg-white rounded-lg shadow-md p-6 dark:bg-gray-800 dark:text-white">
    <h2 class="text-2xl font-bold mb-4 text-gray-900 dark:text-white">Profile</h2>
    
    <div v-if="user" class="space-y-2">
      <div class="flex justify-between">
        <span class="font-semibold text-gray-700 dark:text-gray-400">Name:</span>
        <span>{{ user.first_name }} {{ user.last_name }}</span>
      </div>
      
      <div class="flex justify-between">
        <span class="font-semibold text-gray-700 dark:text-gray-400">Email:</span>
        <span>{{ user.email }}</span>
      </div>
      
      <div class="flex justify-between">
        <span class="font-semibold text-gray-700 dark:text-gray-400">Phone Number:</span>
        <span>{{ user.phone_number }}</span>
      </div>
      
      <div class="flex justify-between">
        <span class="font-semibold text-gray-700 dark:text-gray-400">National ID:</span>
        <span>{{ user.national_id }}</span>
      </div>

      <button 
        @click="logout" 
        class="mt-6 w-full py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 focus:ring-4 focus:ring-red-300 dark:focus:ring-red-800"
      >
        Logout
      </button>
    </div>
  </div>
</template>

<script>

import axios from 'axios';
import { API_BASE_URL } from '../config';
export default {
  name: 'ProfilePage',
  data() {
    return {
      user: null
    };
  },
  async created() {
    try {
      const response = await axios.get(`${API_BASE_URL}/profile`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      this.user = response.data.user;
    } catch (error) {
      console.error(error.response.data);
    }
  },
  methods: {
    async logout() {
      await axios.post(`${API_BASE_URL}/logout`, {}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      localStorage.removeItem('token');
      this.$router.push('/');
    }
  }
}
</script>
