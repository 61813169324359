<template>
    <div class="bg-white p-6 rounded-lg shadow-md">
      <!-- Greeting -->
      <h2 class="text-xl font-semibold text-gray-800 mb-4">Hi {{ userName }}, your trading snapshot is below:</h2>
      
      <!-- Deposits Section -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
        <!-- Current Deposit Card -->
        <div class="bg-gray-100 p-4 rounded-lg shadow">
          <h3 class="text-lg font-semibold text-gray-700">Current Deposit</h3>
          <p class="text-2xl font-bold text-green-600">{{ currentDeposit }} USD</p>
        </div>
  
        <!-- Current Earnings Card -->
        <div class="bg-gray-100 p-4 rounded-lg shadow">
          <h3 class="text-lg font-semibold text-gray-700">Current Earnings</h3>
          <p class="text-2xl font-bold text-blue-600">{{ currentEarnings }} USD</p>
        </div>
      </div>
  
      <!-- Progress Bar Section -->
      <div class="mt-6">
        <h3 class="text-lg font-semibold text-gray-700 mb-2">Investment Progress</h3>
        <div class="w-full bg-gray-200 rounded-full h-2.5">
          <div class="bg-blue-500 h-2.5 rounded-full" :style="{ width: progress + '%' }"></div>
        </div>
        <p class="text-sm text-gray-600 mt-2">{{ progress }}% of your target achieved</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'TradingSnapshot',
    props: {
      userName: {
        type: String,
        required: true,
      },
      currentDeposit: {
        type: Number,
        required: true,
      },
      currentEarnings: {
        type: Number,
        required: true,
      },
      progress: {
        type: Number,
        required: true,
      },
    },
  }
  </script>
  
  <style scoped>
  /* Add any additional styling here */
  </style>
  