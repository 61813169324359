<template>
    <div class="max-w-lg mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
      <h2 class="text-2xl font-bold mb-6">Update Profile</h2>
      <form @submit.prevent="updateProfile">
        <div v-if="currentUser">
          <div class="mb-4">
            <label class="block text-sm font-medium text-gray-700">First Name</label>
            <input
              type="text"
              v-model="form.first_name"
              class="w-full mt-1 p-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              :placeholder="currentUser.first_name"
            />
            <p v-if="errors.first_name" class="text-red-500 text-sm">{{ errors.first_name }}</p>
          </div>
  
          <div class="mb-4">
            <label class="block text-sm font-medium text-gray-700">Last Name</label>
            <input
              type="text"
              v-model="form.last_name"
              class="w-full mt-1 p-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              :placeholder="currentUser.last_name"
            />
            <p v-if="errors.last_name" class="text-red-500 text-sm">{{ errors.last_name }}</p>
          </div>
  
          <div class="mb-4">
            <label class="block text-sm font-medium text-gray-700">Email Address</label>
            <input
              type="email"
              v-model="form.email"
              class="w-full mt-1 p-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              :placeholder="currentUser.email"
            />
            <p v-if="errors.email" class="text-red-500 text-sm">{{ errors.email }}</p>
          </div>
  
          <div class="mb-4">
            <label class="block text-sm font-medium text-gray-700">Phone Number</label>
            <input
              type="text"
              v-model="form.phone_number"
              class="w-full mt-1 p-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              :placeholder="currentUser.phone_number"
            />
            <p v-if="errors.phone_number" class="text-red-500 text-sm">{{ errors.phone_number }}</p>
          </div>
  
          <div class="mb-4">
            <label class="block text-sm font-medium text-gray-700">National ID</label>
            <input
              type="text"
              v-model="form.national_id"
              class="w-full mt-1 p-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              :placeholder="currentUser.national_id"
            />
            <p v-if="errors.national_id" class="text-red-500 text-sm">{{ errors.national_id }}</p>
          </div>
  
          <!-- Avatar Section with Placeholder -->

       <div class="mb-4">
          <label class="block text-sm font-medium text-gray-700">Avatar</label>
          <input type="file" ref="avatar" @change="onAvatarChange" class="w-full mt-1 p-2 border border-gray-300 rounded-lg" />
          <div class="mt-4">
            <p class="text-gray-500">Avatar Preview:</p>
            <img 
              :src="avatarPreview || placeholderAvatar" 
              alt="Avatar Preview" 
              class="w-24 h-24 rounded-full border mt-2" 
            />
          </div>
        </div>

        <button
          type="submit"
          class="w-full py-2 mt-4 text-white bg-blue-600 hover:bg-blue-700 rounded-lg font-medium"
        >
          Save Changes
        </button>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  import { inject, computed, ref, watch, nextTick, onMounted } from 'vue';
  import axios from 'axios';
  import { API_BASE_URL } from '../../config';
  
  export default {
    name: 'MyProfilePage',
    setup() {
      const user = inject('user');
      const currentUser = computed(() => user?.value || null);
  
      // Refs
      const avatarRef = ref(null);
  
      // Form data
      const form = ref({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        national_id: '',
        avatar: null,
      });
  
      // Errors object
      const errors = ref({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        national_id: '',
      });
  
      // Avatar preview URL
      const placeholderAvatar = 'https://avatar.iran.liara.run/public'; 
      const avatarPreview = ref(placeholderAvatar);
  
      const onAvatarChange = async () => {
        await nextTick();  // Ensure DOM is updated

        console.log('avatarRef:', avatarRef.value); // Check if the ref is now available

        const file = avatarRef.value?.files[0];
        if (file) {
            console.log('Selected file:', file); // Log the selected file to confirm it's being picked
            form.value.avatar = file;
            avatarPreview.value = URL.createObjectURL(file);
        } else {
            console.log('No file selected, using placeholder');
            avatarPreview.value = placeholderAvatar;
        }
        };
        onMounted(() => {
        console.log('avatarRef in mounted:', avatarRef.value);
        });
      // Watch for changes in the current user
      watch(currentUser, (newUser) => {
        
        if (newUser) {
        
          form.value.first_name = newUser.first_name || '';
          form.value.last_name = newUser.last_name || '';
          form.value.email = newUser.email || '';
          form.value.phone_number = newUser.phone_number || '';
          form.value.national_id = newUser.national_id || '';
        }
      });
  
      // Validation function
      const validateForm = () => {
        errors.value = {
          first_name: '',
          last_name: '',
          email: '',
          phone_number: '',
          national_id: '',
        };
  
        let isValid = true;
  
        if (!form.value.first_name) {
          errors.value.first_name = 'First Name is required';
          isValid = false;
        }
        if (!form.value.last_name) {
          errors.value.last_name = 'Last Name is required';
          isValid = false;
        }
        if (!form.value.email || !/\S+@\S+\.\S+/.test(form.value.email)) {
          errors.value.email = 'Valid Email Address is required';
          isValid = false;
        }
        if (!form.value.phone_number) {
          errors.value.phone_number = 'Phone Number is required';
          isValid = false;
        }
        if (!form.value.national_id) {
          errors.value.national_id = 'National ID is required';
          isValid = false;
        }
  
        return isValid;
      };
  
      // Submit the profile update
      const updateProfile = async () => {
        if (validateForm()) {
          try {
            const formData = new FormData();
            
            // Append form data (skip null fields)
            Object.keys(form.value).forEach((key) => {
              const value = form.value[key];
              if (value !== null && value !== undefined && value !== '') {
                formData.append(key, value);
              }
            });
            formData.forEach((value, key) => {
                console.log(`FormData Key: ${key}, Value: ${value}`);
            });
            // Make the API request
            const response = await axios.post(`${API_BASE_URL}/profile`, formData, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                //'Content-Type': 'multipart/form-data',
              },
            });
  
            // Reset error state if successful
            errors.value = {
              first_name: '',
              last_name: '',
              email: '',
              phone_number: '',
              national_id: '',
            };
            // Handle successful response here (optional)
            console.log('Profile updated successfully:', response.data);
          } catch (error) {
            console.error('Failed to update profile', error.response?.data || error.message);


            if (error.response && error.response.data) {
                // Assign the errors from the response to the `errors` object
                const apiErrors = error.response.data.errors || {};
                errors.value.first_name = apiErrors.first_name ? apiErrors.first_name[0] : '';
                errors.value.last_name = apiErrors.last_name ? apiErrors.last_name[0] : '';
                errors.value.email = apiErrors.email ? apiErrors.email[0] : '';
                errors.value.phone_number = apiErrors.phone_number ? apiErrors.phone_number[0] : '';
                errors.value.national_id = apiErrors.national_id ? apiErrors.national_id[0] : '';

            }          
          }
        }
      };
  
      return {
        currentUser,
        form,
        avatarPreview,
        placeholderAvatar,
        avatarRef,
        onAvatarChange,
        updateProfile,
        errors,
      };
    },

  };
  </script>